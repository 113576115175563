import React from 'react';
import { styled } from '@mui/system';
import { connect } from 'react-redux';
import WelcomeMessage from './WelcomeMessage';
import MessengerContent from './MessengerContent';

const MainContainer = styled('div')({
    flexGrow: 1,
    backgroundColor: '#36393f',
    marginTop: '48px',
    display: 'flex',
});

const Messenger = ({ chosenChatDetails }: any) => {
    // chosenChatDetails : messgae contenet prop redux

    return (
        <MainContainer>
            {!chosenChatDetails ? <WelcomeMessage /> : <MessengerContent chosenChatDetails={chosenChatDetails} />}
        </MainContainer>
    );
};

const mapStoreStateToProps = ({ chat }: any) => {
    return {
        ...chat,
    };
};

export default connect(mapStoreStateToProps)(Messenger);
